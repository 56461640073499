
/* Index Buttons Get, Add, Search Buttons 
.index-btn.MuiButtonBase-root-MuiButton-root:hover {
    background: linear-gradient(90deg, #06003d 0%, #320036 100%);
    transition: background-color 0.3s ease !important;
}
.index-btn.MuiButtonBase-root-MuiButton-root {
    background: linear-gradient(100deg, rgb(42, 0, 46) 0%, #100029 100%);
    transition: background-color 0.1s ease-in-out !important;
} */

/* Input Label For Add Site  */
.MuiFormLabel-root-MuiInputLabel-root{
    color: black !important;
}

/* Add Site and Edit,Delete Site Buttons  */
/* .css-k350n1-MuiButtonBase-root-MuiButton-root{
    background: linear-gradient(90deg, #14003a 0%, #450047 100%);
    transition: background-color 0.1s ease-in-out !important;
    
}
.css-k350n1-MuiButtonBase-root-MuiButton-root:hover{
    background: linear-gradient(90deg, #34003b 0%, #1e0057 100%);
    transition: background-color 0.3s ease !important;
} */

/* Search Button  */
.css-zcbmsk-MuiButtonBase-root-MuiButton-root:hover{
    background: linear-gradient(90deg, #06003d 0%, #320036 100%);
    transition: background-color 0.1s ease-in-out !important;
}

.css-zcbmsk-MuiButtonBase-root-MuiButton-root{
    background: linear-gradient(100deg, rgb(42, 0, 46) 0%, #100029 100%);
    transition: background-color 0.3s ease !important;
}

/* Input Box  */
.MuiFormControl-root-MuiTextField-root:focus{
    color:rgb(0, 0, 68) !important;
}
.MuiFormControl-root-MuiTextField-root{
    color:rgb(0, 0, 68) !important;
}

/* Edit Site Button  */
#edit-btn.css-3ovtof-MuiButtonBase-root-MuiButton-root{
    background: linear-gradient(90deg, #000000 0%, #130036 100%);
    transition: background-color 0.3s ease !important;
}
#edit-btn.css-3ovtof-MuiButtonBase-root-MuiButton-root:hover{
    background: linear-gradient(90deg, #000000 0%, #006269 100%);
    transition: background-color 0.3s ease !important;
}

/* Delete Site Button  */
#delete-btn.css-3ovtof-MuiButtonBase-root-MuiButton-root{
    background: linear-gradient(90deg, #000000 0%, #130036 100%);
    transition: background-color 0.3s ease !important;
}
#delete-btn.css-3ovtof-MuiButtonBase-root-MuiButton-root:hover{
    background: linear-gradient(90deg, #000000 0%, #860000 100%);
    transition: background-color 0.3s ease !important;
}

/* Details Button  */
#details-btn.css-3ovtof-MuiButtonBase-root-MuiButton-root{
    background: linear-gradient(90deg, #000000 0%, #130036 100%);
    transition: background-color 0.3s ease !important;
}
#details-btn.css-3ovtof-MuiButtonBase-root-MuiButton-root:hover{
    background: linear-gradient(90deg, #000000 0%, #969900 100%);
    transition: background-color 0.3s ease !important;
}

/* Details Button  */
#details-btn.css-1a72v40-MuiButtonBase-root-MuiButton-root{
    background: linear-gradient(90deg, #000000 0%, #130036 100%);
    transition: background-color 0.3s ease !important;
}
#details-btn.css-1a72v40-MuiButtonBase-root-MuiButton-root:hover{
    background: linear-gradient(90deg, #000000 0%, #969900 100%);
    transition: background-color 0.3s ease !important;
}

/*Zone Details In Data Grid Button  */
#details-btn.css-11qr2p8-MuiButtonBase-root-MuiButton-root{
    background: linear-gradient(90deg, #000000 0%, #130036 100%);
    transition: background-color 0.3s ease !important;
}
#details-btn.css-11qr2p8-MuiButtonBase-root-MuiButton-root:hover{
    background: linear-gradient(90deg, #000000 0%, #969900 100%);
    transition: background-color 0.3s ease !important;
}

/* Get All Sites Button  */
/* .css-kw0x9v-MuiButtonBase-root-MuiButton-root{
    background: linear-gradient(100deg, rgb(42, 0, 46) 0%, #1b0046 100%);
    transition: background-color 0.1s ease-in-out !important;
}
.css-kw0x9v-MuiButtonBase-root-MuiButton-root:hover{
    background: linear-gradient(90deg, #06003d 0%, #320036 100%);
    transition: background-color 0.3s ease !important;
} */

/* Get All Sites Button  */
/* .css-1hpzgh0-MuiButtonBase-root-MuiButton-root{
    background: linear-gradient(100deg, rgb(75, 0, 82) 0%, #18003d 100%);
    transition: background-color 0.1s ease-in-out !important;
}
.css-1hpzgh0-MuiButtonBase-root-MuiButton-root:hover{
    background: linear-gradient(100deg, #06003d 0%, #5a0061 100%);
    transition: background-color 0.3s ease !important;
} */


/* Site Details css  */

/* Edit Button  */
#edit-button.css-1a07xq7-MuiButtonBase-root-MuiButton-root{
    background: linear-gradient(90deg, #000000 0%, #130036 100%);
    transition: background-color 0.3s ease !important;
}
#edit-button.css-1a07xq7-MuiButtonBase-root-MuiButton-root:hover{
    background: linear-gradient(90deg, #000000 0%, #004c52 100%);
    transition: background-color 0.3s ease !important;
}

/* Delete Button  */
#delete-button.css-1a07xq7-MuiButtonBase-root-MuiButton-root{
    background: linear-gradient(90deg, #000000 0%, #130036 100%);
    transition: background-color 0.3s ease !important;
}
#delete-button.css-1a07xq7-MuiButtonBase-root-MuiButton-root:hover{
    background: linear-gradient(90deg, #000000 0%, #720000 100%);
    transition: background-color 0.3s ease !important;
}

/* Add Button  */
#add-button.css-1a07xq7-MuiButtonBase-root-MuiButton-root{
    background: linear-gradient(90deg, #000000 0%, #130036 100%);
    transition: background-color 0.3s ease !important;
}
#add-button.css-1a07xq7-MuiButtonBase-root-MuiButton-root:hover{
    background: linear-gradient(90deg, #000000 0%, #004e00 100%);
    transition: background-color 0.3s ease !important;
}

/* Add Button  */
#showZones-button.css-1a07xq7-MuiButtonBase-root-MuiButton-root{
    background: linear-gradient(90deg, #000000 0%, #130036 100%);
    transition: background-color 0.3s ease !important;
}
#showZones-button.css-1a07xq7-MuiButtonBase-root-MuiButton-root:hover{
    background: linear-gradient(90deg, #000000 0%, #867d00 100%);
    transition: background-color 0.3s ease !important;
}

/* Site Details App bar  */
.css-10ghrmp-MuiPaper-root-MuiAppBar-root{
    background: linear-gradient(90deg, #41005f 0%, rgb(16, 2, 94) 100%);
    transition: background-color 0.3s ease !important;
}
.css-10ghrmp-MuiPaper-root-MuiAppBar-root:hover{
    background: linear-gradient(90deg, #070046 0%, rgb(58, 0, 92) 100%);
    transition: background-color 0.3s ease !important;
}

/* DltZone and EditZone Buttons and save changes btn  */
/* Edit Button  */
#edit-button.css-1l08nm1-MuiButtonBase-root-MuiButton-root{
    background: linear-gradient(90deg, #000000 0%, #130036 100%);
    transition: background-color 0.3s ease !important;
}
#edit-button.css-1l08nm1-MuiButtonBase-root-MuiButton-root:hover{
    background: linear-gradient(90deg, #000000 0%, #004c52 100%);
    transition: background-color 0.3s ease !important;
}

/* Delete Button  */
#delete-button.css-1l08nm1-MuiButtonBase-root-MuiButton-root{
    background: linear-gradient(90deg, #000000 0%, #130036 100%);
    transition: background-color 0.3s ease !important;
}
#delete-button.css-1l08nm1-MuiButtonBase-root-MuiButton-root:hover{
    background: linear-gradient(90deg, #000000 0%, #720000 100%);
    transition: background-color 0.3s ease !important;
}

/* Update Changes and Add User to Zone btn*/
.css-edsu1d-MuiButtonGroup-root .MuiButtonGroup-firstButton{
    background: linear-gradient(90deg, #000000 0%, #130036 100%);
    transition: background-color 0.3s ease !important;
}
.css-edsu1d-MuiButtonGroup-root .MuiButtonGroup-firstButton:hover{
    background: linear-gradient(90deg, #000000 0%, #3d6b00 100%);
    transition: background-color 0.3s ease !important;
}

.css-1bokhev-MuiButtonBase-root-MuiButton-root.Mui-disabled{
    background: linear-gradient(90deg, #000000b2 0%, #130036c7 100%);
    transition: background-color 0.3s ease !important;
    color: rgba(255, 255, 255, 0.808) !important;
}

/* Add User To Site Button  */
.css-jp0eqm-MuiButtonBase-root-MuiButton-root{
    background: linear-gradient(90deg, #000000 0%, #130036 100%);
    transition: background-color 0.3s ease !important;
}
.css-jp0eqm-MuiButtonBase-root-MuiButton-root:hover{
    background: linear-gradient(90deg, #000000 0%, #003610 100%);
    transition: background-color 0.3s ease !important;
}

/* Add User To Site Button When Disabled  */
.css-jp0eqm-MuiButtonBase-root-MuiButton-root.Mui-disabled{
    background: linear-gradient(90deg, #000000cb 0%, #140038ce 100%);
    transition: background-color 0.3s ease !important;
    color: rgba(255, 255, 255, 0.726) !important;
}

/* Remove User Button  */
.css-11qr2p8-MuiButtonBase-root-MuiButton-root{
    background: linear-gradient(90deg, #000000 0%, #130036 100%);
    transition: background-color 0.3s ease !important;
}
.css-11qr2p8-MuiButtonBase-root-MuiButton-root:hover{
    background: linear-gradient(90deg, #000000 0%, #c73800 100%);
    transition: background-color 0.3s ease !important;
}



