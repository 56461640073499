.MuiFormHelperText-root{
    color: #d32f2f !important; 
}

.css-12cv75f-MuiButtonBase-root-MuiButton-root{
    background: linear-gradient(90deg, #000000 0%, #230064 100%);
    transition: background-color 0.3s ease !important;
}
.css-12cv75f-MuiButtonBase-root-MuiButton-root:hover{
    background: linear-gradient(180deg, rgb(0, 5, 80) 100%, #000000 100%);
    transition: background-color 0.1s ease-in-out !important;
}

div::selection{
    background-color: black;
    color: white;
}